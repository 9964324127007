import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TechBlogList from "../components/tech-blog-list"
import TravelBlogList from "../components/travel-blog-list"

const MyPosts = () => (
  <Layout>
    <Seo title="MyPosts" />
    <div
      style={{
        textAlign: "center",
        fontFamily: "Homemade Apple",
        padding: "20px",
      }}
    >
      <h1 style={{ color: "#EFC88B", paddingBottom: "10px" }}>Hey folks...</h1>
      <p style={{ color: "lightseagreen", fontSize: "30px" }}>
        A foray of Tech&ensp;Learnings&ensp;and&ensp;Art&ensp;Outlooks
      </p>
    </div>
    <TechBlogList />
    <TravelBlogList />
  </Layout>
)

export default MyPosts
