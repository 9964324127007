import React from "react"
import { navigate } from "gatsby"
import * as IndexCardStyles from "./single-card.module.css"
import { Card, Image } from "react-bootstrap"

export function getRandomColor() {
  /* let colorValues = [
    "darksalmon",
    "lightcoral",
    "indianred",
    "#ED6A5E",
    "#FF8E72",
  ] */
  let colorValues = ["rgb(231, 168, 30)"]
  return colorValues[Math.floor(Math.random() * colorValues.length)]
}

export default function SingleCard({ data }) {
  return (
    <Card
      className={IndexCardStyles.cardSt}
      style={{ backgroundColor: getRandomColor() }}
      onClick={() => navigate(data.fields.slug)}
    >
      <div className={IndexCardStyles.imgOverflow}>
        <Card.Img
          as={Image}
          src={data.frontmatter.featuredImage.publicURL}
          fluid={true}
          alt="Card image"
          className={IndexCardStyles.CardImgSt}
        />
      </div>
      <div>
        <Card.Body>
          <small className={IndexCardStyles.timeSt}>
            {data.frontmatter.date}
          </small>
          <Card.Title className={IndexCardStyles.postTitle}>
            {data.frontmatter.title}
          </Card.Title>
          <Card.Text className={IndexCardStyles.textSt}>
            {data.excerpt}
          </Card.Text>
        </Card.Body>
      </div>
    </Card>
  )
}
