import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CardColumns } from "react-bootstrap"
import SingleCard from "./single-card"

export function getRandomColor() {
  let colorValues = ["darksalmon", "lightcoral", "indianred"] //, "lavender", "darkseagreen", "khaki"]
  return colorValues[Math.floor(Math.random() * colorValues.length)]
}

export default function TechBlogList() {
  const data = useStaticQuery(graphql`
    query TechBlogListQuery {
      blog: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { template: { eq: "tech-post" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date(fromNow: true)
              title
              author
              description
              tags
              template
              featuredImage {
                relativePath
                absolutePath
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            excerpt(pruneLength: 100)
            id
          }
        }
      }
    }
  `)
  const allPosts = data.blog.edges

  return (
    <CardColumns>
      {allPosts.map(({ node }) => (
        /* {bgCol = this.getRandomColor} */
        <SingleCard data={node} />
      ))}
    </CardColumns>
  )
}
